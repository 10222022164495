<template>
  <form-validation-field
    name="type"
    label="Type"
    component="BFormSelect"
    :options="types"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    FormValidationField,
  },
  data() {
    return {
      types: [
        { value: 'season', text: 'Season' },
        { value: 'playoff', text: 'Playoff' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
