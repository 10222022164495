<template>
  <b-form-group
    label="Status"
    label-for="status"
  >
    <b-form-select
      name="status"
      :options="statuses"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: 'not started',
    },
  },
  data() {
    return {
      statuses: [
        { text: 'Not Started', value: 'not started' },
        { text: 'Completed', value: 'completed' },
        { text: 'Forfeit', value: 'forfeit' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
