<template>
  <form-validation-field
    ref="field"
    component="flatPickr"
    :value="value"
    :name="name"
    :validation="{ detectInput: false, ...validation, }"
    :input-class="`flatpickr-input form-control ${inputClass}`"
    :config="config"
    v-bind="$attrs"
    v-on="$listeners"
    @on-close="checkIfInvalid"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    inputClass() {
      return `input-${this.name}`
    },
  },
  watch: {
    value() {
      this.checkIfInvalid()
    },
  },
  mounted() {
    if (this.value) {
      this.checkIfInvalid()
    }
  },
  methods: {
    async checkIfInvalid() {
      const { valid } = await this.$refs.field.$refs.validation.validate(this.value)

      // Class list does not update for alt input so have to manually update class list.
      if ('altInput' in this.config) {
        const humanInput = document.querySelector(`.${this.inputClass}`).nextSibling

        if (!valid) {
          humanInput.classList.add('is-invalid')
          return
        }

        humanInput.classList.remove('is-invalid')
      }
    },
  },
}
</script>

<style lang="scss">
//.flatpickr-input[type=hidden] {
//  display: none;
//  width: 0;
//}

[dir=ltr] .input-group > .form-control + .form-control {
  margin-left: 0;
}

body .flatpickr-input {
  margin: 0;
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
