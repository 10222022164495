var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.season)?_c('section',[(_vm.addGame)?_c('add-game',{attrs:{"venues":_vm.venues},on:{"add-game":_vm.addGameToSchedule,"cancel":function($event){_vm.addGame = false}}}):_vm._e(),_c('edit-game',{attrs:{"venues":_vm.venues,"game":_vm.game},on:{"update-game":_vm.updateGame,"cancel":_vm.handleEditGameCancel},model:{value:(_vm.editGame),callback:function ($$v) {_vm.editGame=$$v},expression:"editGame"}}),_c('b-card',{attrs:{"no-body":""}},[_c('card-header-filters',{attrs:{"title":"Schedule","button":{ show: !_vm.addGame, text: 'Game' },"import-button":true,"filters":_vm.filters},on:{"add":function($event){_vm.addGame = true},"import":function($event){_vm.importGames = true},"reset-filters":_vm.handleResetFilter},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('select-division',{attrs:{"is-filter":""},on:{"change":function($event){_vm.$nextTick(function () { return _vm.getGames(); })}},model:{value:(_vm.filters.division),callback:function ($$v) {_vm.$set(_vm.filters, "division", $$v)},expression:"filters.division"}}),_c('select-team',{attrs:{"is-filter":"","filter-by-division":_vm.filters.division,"include-free-agent":false},on:{"change":_vm.getGames},model:{value:(_vm.filters.team),callback:function ($$v) {_vm.$set(_vm.filters, "team", $$v)},expression:"filters.team"}}),_c('b-form-group',{attrs:{"label":"Venue","label-for":"venue-filter"}},[_c('b-form-select',{attrs:{"id":"venue-filter","value-field":"id","text-field":"name","options":[
              'All' ].concat( _vm.venues )},on:{"input":_vm.getGames},model:{value:(_vm.filters.venue),callback:function ($$v) {_vm.$set(_vm.filters, "venue", $$v)},expression:"filters.venue"}})],1)]},proxy:true},{key:"filter-append",fn:function(){return [_c('b-col',{staticClass:"align-self-center",attrs:{"md":"auto"}},[_c('b-form-group',{attrs:{"label":"Date Range","label-for":"date-range"}},[_c('b-form-radio-group',{attrs:{"id":"date-range","button-variant":"outline-primary","buttons":"","options":_vm.dateRanges},on:{"change":_vm.handleDateRangeChange},model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}},[_c('b-button',{ref:"customButton",class:{ active: _vm.filters.dateRange.selected === 'Custom' },attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$refs.datePicker.fp.open()}}},[_vm._v(" "+_vm._s(_vm.customDateRange || 'Custom')+" ")])],1)],1)],1),_c('flat-pickr',{ref:"datePicker",staticClass:"d-none",attrs:{"config":{ mode: 'range', positionElement: _vm.$refs.customButton}},on:{"on-change":_vm.handleCustomDateRangeChange},model:{value:(_vm.customDateRange),callback:function ($$v) {_vm.customDateRange=$$v},expression:"customDateRange"}})]},proxy:true}],null,false,1934243511)}),_c('table-list',{attrs:{"primary-key":"id","responsive":"lg","item-name":"game","table-class":"schedule-table","current-page":1,"items":_vm.gamesFormatted,"fields":_vm.fields,"busy":_vm.isLoading,"meta-data":_vm.metaData,"endpoints":{ delete: '/games' },"delete-modal":function (ref) {
                  var item = ref.item;

                  return ({
        itemName: ((item.date) + " at " + (item.time)),
        text: ("Are you sure you want delete <b>" + (item.teams.home.name) + "</b> vs <b>" + (item.teams.visitor.name) + "</b>?"),
        afterDelete: function () { return _vm.handleDeleteGame(item); }
      });
}},on:{"change-per-page":_vm.getGames,"change-pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"cell(date)",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.dateFormatted))]),(item.date || item.time)?_c('span',{staticClass:"d-block text-muted sub-title"},[_vm._v(" "+_vm._s(item.timeFormatted)+" ")]):_vm._e()]}},{key:"cell(venue)",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.venue ? item.venue.name : 'TBD'))]),(item.venue || item.playing_surface)?_c('span',{staticClass:"d-block text-muted sub-title"},[_vm._v(" "+_vm._s(item.playing_surface ? item.playing_surface.name : 'TBD')+" ")]):_vm._e()]}},_vm._l((['home', 'visitor']),function(team){return {key:_vm.dynamicCell(team),fn:function(ref){
      var item = ref.item;
return [_c('div',{key:team},[_vm._v(" "+_vm._s(item[team].name)+" "),_c('span',{staticClass:"d-block text-muted sub-title"},[_vm._v(" "+_vm._s(item[team].division ? item[team].division.name : '')+" ")])])]}}}),{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('b-button',{attrs:{"text":"","size":"sm","variant":"flat-primary"},on:{"click":function($event){return _vm.showEditGame(item)}}},[_vm._v(" Edit ")]),_c('b-button',{attrs:{"text":"","size":"sm","variant":"flat-primary"},on:{"click":function($event){return _vm.$router.push({ name: 'event.game.stats', params: { game: item.id } })}}},[_vm._v(" Stats ")])]}}],null,true)})],1),_c('modal-import-games',{attrs:{"title":"Import Games","end-point":("seasons/" + (this.$store.state.event.seasons.season.id) + "/games/import")},on:{"cancel":function($event){_vm.importGames = false},"import-successful":_vm.handleImportSuccessful},model:{value:(_vm.importGames),callback:function ($$v) {_vm.importGames=$$v},expression:"importGames"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }