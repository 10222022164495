<template>
  <section>
    <b-form-row>
      <!-- Date -->
      <b-col
        cols="6"
        md="2"
      >
        <form-validation-date-picker
          ref="date"
          v-model="game.date"
          vid="date"
          label="Date"
          name="date"
          aria-autocomplete="none"
          :config="{
            altInput: true,
            dateFormat: 'Y-m-d'
          }"
        />
      </b-col>

      <!-- Time -->
      <b-col
        cols="6"
        md="auto"
      >
        <form-validation-field
          vid="time"
          name="time"
          label="Time"
          type="time"
          input-class="form-control"
          component="input"
          :value="game.time"
          @change="game.time = $event.target.value"
          @input="game.time = $event.target.value"
        />
      </b-col>

      <!-- Venue -->
      <b-col
        cols="6"
        md="2"
      >
        <form-validation-field
          v-model="game.venue_id"
          vid="venue_id"
          name="venue"
          label="Venue"
          component="BFormSelect"
          value-field="id"
          text-field="name"
          :options="[
            { id: null, name: '-' },
            ...venues
          ]"
        />
      </b-col>

      <!-- Playing Surface -->
      <b-col
        cols="6"
        md="2"
      >
        <select-playing-surface
          v-model="game.playing_surface_id"
          vid="playing_surface_id"
          :venues="venues"
          :venue="game.venue_id"
          :sport="$store.state.event.event.sport"
        />
      </b-col>

      <!-- Type -->
      <b-col
        cols="6"
        md="auto"
      >
        <select-game-type
          v-model="game.type"
          vid="type"
          rules="required"
        />
      </b-col>

      <!-- Note -->
      <b-col
        cols="6"
        md="2"
      >
        <form-validation-field
          v-model="game.note"
          vid="note"
          name="note"
          label="Note"
          aria-autocomplete="none"
        />
      </b-col>
    </b-form-row>

    <b-form-row>
      <!-- Home -->
      <b-col
        cols="6"
        md="2"
      >
        <select-team
          v-model="game.home_team_id"
          vid="home_team_id"
          name="home"
          label="Home*"
          rules="required"
          :include-free-agent="false"
        />
      </b-col>

      <!-- Home Score-->
      <b-col
        cols="6"
        md="1"
      >
        <form-validation-field
          v-model="game.home_score"
          vid="home_score"
          name="home_score"
          label="Score"
          rules="integer"
          type="number"
          inputmode="numeric"
        />
      </b-col>

      <!-- Visitor -->
      <b-col
        cols="6"
        md="2"
      >
        <select-team
          v-model="game.visitor_team_id"
          vid="visitor_team_id"
          name="visitor"
          label="Visitor*"
          rules="required"
          :include-free-agent="false"
        />
      </b-col>

      <!-- Visitor Score-->
      <b-col
        cols="6"
        md="1"
      >
        <form-validation-field
          v-model="game.visitor_score"
          vid="visitor_score"
          name="visitor_score"
          label="Score"
          rules="integer"
          type="number"
          inputmode="numeric"
        />
      </b-col>

      <!-- Status -->
      <b-col md="auto">
        <select-game-status v-model="game.status" />
      </b-col>

      <slot name="submit-buttons" />
    </b-form-row>
  </section>
</template>

<script>
import { BFormRow, BCol } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormValidationDatePicker from '@/components/forms/validation/FormValidationDatePicker.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectGameType from '@/components/forms/selects/SelectGameType.vue'
import SelectPlayingSurface from '@/components/forms/selects/SelectPlayingSurface.vue'
import SelectGameStatus from '@/components/forms/selects/SelectGameStatus.vue'

export default {
  components: {
    BFormRow,
    BCol,
    FormValidationDatePicker,
    FormValidationField,
    SelectTeam,
    SelectPlayingSurface,
    SelectGameType,
    SelectGameStatus,
  },
  props: {
    game: {
      required: true,
      type: Object,
    },
    venues: {
      required: true,
      type: Array,
    },
  },
  computed: {
    venue() {
      return this.game.venue_id
    },
    // Extract only the scores properties for watcher.
    scores() {
      return {
        homeScore: this.game.home_score,
        visitorScore: this.game.visitor_score,
      }
    },
  },
  watch: {
    venue(value) {
      if (!value) {
        // If there is no venue make the playing surface null.
        this.game.playing_surface_id = null
      }
    },
    // Watch user entering scores and update the game's status.
    scores(newValue) {
      // If there are no scores the game did not start.
      if (!newValue.homeScore && !newValue.visitorScore) {
        this.game.status = 'not started'
        return
      }

      // If game status is forfeit don't change the status when typing in new scores.
      if (this.game.status === 'forfeit') {
        return
      }

      this.game.status = 'completed'
    },
  },
}
</script>

<style scoped>

</style>
