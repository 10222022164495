<template>
  <modal-card title="Add Game">
    <template #default="{ isCard }">
      <validation-form
        ref="validationForm"
        v-slot="{ observer }"
        @before-validation="$refs.formGame.$refs.date.checkIfInvalid()"
        @validated="addGame"
      >
        <form-game
          ref="formGame"
          :game="form"
          :venues="venues"
        >
          <template #submit-buttons>
            <!-- Buttons -->
            <portal
              to="modal-footer-add-game"
              :disabled="isCard"
            >
              <submit-buttons
                class="mt-sm-2 ml-sm-75"
                :loading="form.isLoading"
                :invalid="observer.failed"
                v-on="$listeners"
                @save="$refs.validationForm.validate()"
              />
            </portal>
          </template>
        </form-game>
      </validation-form>
    </template>

    <template #modal-footer>
      <portal-target name="modal-footer-add-game" />
    </template>
  </modal-card>
</template>

<script>
import FormGame from '@/components/forms/FormGame.vue'
import ModalCard from '@/components/modals/ModalCard.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    SubmitButtons,
    ValidationForm,
    ModalCard,
    FormGame,
  },
  props: {
    venues: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      form: new Form({
        date: '',
        time: '',
        venue_id: undefined,
        playing_surface_id: undefined,
        home_team_id: null,
        home_score: null,
        visitor_team_id: null,
        visitor_score: null,
        type: 'season',
        note: null,
        status: 'not started',
      }),
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
    this.form.keepDataWhenResetting = ['date', 'time', 'venue_id', 'playing_surface_id', 'type']
  },
  methods: {
    addGame() {
      this.form.post(`/seasons/${this.season.id}/games`)
        .then(response => {
          const venue = this.venues.find(team => team.id === this.form.venue_id)
          const playingSurface = venue
            ? venue.playing_surfaces.find(surface => surface.id === this.form.playing_surface_id)
            : null

          this.$emit('add-game', {
            ...response.data,
            venue,
            playing_surface: playingSurface,
          })

          // Reset form.
          this.form.reset()
        })
    },
  },
}
</script>

<style lang="scss" scoped>

@media (min-width: 768px) {
  .col-md-1-5 {
    flex: 0 0 10.3%;
    max-width: 10.3%;
  }
}
</style>
