<template>
  <div>
    <form-validation-field
      vid="playing_surface_id"
      component="BFormSelect"
      value-field="id"
      text-field="name"
      :name="playingSurfaceType.toLowerCase()"
      :label="`${playingSurfaceType}`"
      :value="value"
      :options="playingSurfaces"
      :disabled="!venue"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!--      <template-->
      <!--        v-if="venue"-->
      <!--        #append-->
      <!--      >-->
      <!--        <b-input-group-append>-->
      <!--          <b-button-->
      <!--            variant="outline-primary"-->
      <!--            :to="{ name: 'organization.venue.playing-surfaces', params: { venue: venue } }"-->
      <!--          >-->
      <!--            +-->
      <!--          </b-button>-->
      <!--        </b-input-group-append>-->
      <!--      </template>-->

      <template #append-form-group>
        <router-link
          v-if="venue && !playingSurfaces.length"
          class="add-playing-surface mt-25"
          :to="{
            name: 'organization.venue.playing-surfaces',
            params: { venue: venue },
            query: { sport: sport, event: $store.state.event.event.id}
          }"
        >
          No {{ playingSurfaceType }}s found. Add {{ playingSurfaceType }}.
        </router-link>
      </template>
    </form-validation-field>
  </div>
</template>

<script>
import { BInputGroupAppend, BButton } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

import { mapState } from 'vuex'

export default {
  components: {
    BInputGroupAppend,
    BButton,
    FormValidationField,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    venues: {
      required: true,
      type: Array,
    },
    venue: {
      type: [String, Number],
      default: null,
    },
    sport: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('app/sport', ['playingSurfaceType']),
    playingSurfaces() {
      const surfaces = this.venue
        ? this.venues.find(venue => venue.id === this.venue).playing_surfaces
        : []

      return [
        { id: null, name: '-' },
        ...surfaces,
      ]
    },
  },
  created() {
    this.$store.commit('app/sport/UPDATE_SPORT', this.sport)
  },
}
</script>

<style lang="scss" scoped>
  .add-playing-surface {
    display: inline-block;
    font-size: .9rem;
  }
</style>
