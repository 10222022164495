<template>
  <section v-if="season">
    <!-- Add New Game -->
    <add-game
      v-if="addGame"
      :venues="venues"
      @add-game="addGameToSchedule"
      @cancel="addGame = false"
    />

    <!-- Edit Game -->
    <edit-game
      v-model="editGame"
      :venues="venues"
      :game="game"
      @update-game="updateGame"
      @cancel="handleEditGameCancel"
    />

    <b-card no-body>
      <!-- Card Header with Filters -->
      <card-header-filters
        title="Schedule"
        :button="{ show: !addGame, text: 'Game' }"
        :import-button="true"
        :filters="filters"
        @add="addGame = true"
        @import="importGames = true"
        @reset-filters="handleResetFilter"
      >
        <template #filters>
          <!-- Division Filter -->
          <select-division
            v-model="filters.division"
            is-filter
            @change="$nextTick(() => getGames())"
          />

          <!-- Team Filter -->
          <select-team
            v-model="filters.team"
            is-filter
            :filter-by-division="filters.division"
            :include-free-agent="false"
            @change="getGames"
          />

          <!-- Venue Filter -->
          <b-form-group
            label="Venue"
            label-for="venue-filter"
          >
            <b-form-select
              id="venue-filter"
              v-model="filters.venue"
              value-field="id"
              text-field="name"
              :options="[
                'All',
                ...venues,
              ]"
              @input="getGames"
            />
          </b-form-group>
        </template>

        <template #filter-append>
          <!-- Date Range Filter -->
          <b-col
            md="auto"
            class="align-self-center"
          >
            <b-form-group
              label="Date Range"
              label-for="date-range"
            >
              <b-form-radio-group
                id="date-range"
                v-model="filters.dateRange"
                button-variant="outline-primary"
                buttons
                :options="dateRanges"
                @change="handleDateRangeChange"
              >
                <b-button
                  ref="customButton"
                  variant="outline-primary"
                  :class="{ active: filters.dateRange.selected === 'Custom' }"
                  @click="$refs.datePicker.fp.open()"
                >
                  {{ customDateRange || 'Custom' }}
                </b-button>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <!-- Date Picker attached to the custom button element (Date Picker cannot be re-rendered otherwise it loses its position) -->
          <flat-pickr
            ref="datePicker"
            v-model="customDateRange"
            :config="{ mode: 'range', positionElement: $refs.customButton}"
            class="d-none"
            @on-change="handleCustomDateRangeChange"
          />
        </template>
      </card-header-filters>

      <!-- Table -->
      <table-list
        primary-key="id"
        responsive="lg"
        item-name="game"
        table-class="schedule-table"
        :current-page="1"
        :items="gamesFormatted"
        :fields="fields"
        :busy="isLoading"
        :meta-data="metaData"
        :endpoints="{ delete: '/games' }"
        :delete-modal="({ item }) => ({
          itemName: `${item.date } at ${item.time}`,
          text: `Are you sure you want delete <b>${item.teams.home.name}</b> vs <b>${item.teams.visitor.name}</b>?`,
          afterDelete: () => handleDeleteGame(item)
        })"
        @change-per-page="getGames"
        @change-pagination="updatePage"
      >
        <!-- Date cell -->
        <template #cell(date)="{ item }">
          <span style="white-space: nowrap">{{ item.dateFormatted }}</span>
          <span
            v-if="item.date || item.time"
            class="d-block text-muted sub-title"
          >
            {{ item.timeFormatted }}
          </span>
        </template>

        <!-- Venue / Facility cell -->
        <template #cell(venue)="{ item }">
          <span>{{ item.venue ? item.venue.name : 'TBD' }}</span>
          <span
            v-if="item.venue || item.playing_surface"
            class="d-block text-muted sub-title"
          >
            {{ item.playing_surface ? item.playing_surface.name : 'TBD' }}
          </span>
        </template>

        <!-- Team cells -->
        <template
          v-for="team in ['home', 'visitor']"
          v-slot:[dynamicCell(team)]="{ item }"
        >
          <div :key="team">
            <!--            <b-link-->
            <!--              :to="{-->
            <!--                name: 'team.edit',-->
            <!--                params: { team: item[team].team_id },-->
            <!--                query: { season_id: season.id }-->
            <!--              }"-->
            <!--            />-->
            {{ item[team].name }}
            <span class="d-block text-muted sub-title">
              {{ item[team].division ? item[team].division.name : '' }}
            </span>
          </div>
        </template>

        <!-- Actions cell -->
        <template #actions="{ item }">
          <b-button
            text
            size="sm"
            variant="flat-primary"
            @click="showEditGame(item)"
          >
            Edit
          </b-button>

          <b-button
            text
            size="sm"
            variant="flat-primary"
            @click="$router.push({ name: 'event.game.stats', params: { game: item.id } })"
          >
            Stats
          </b-button>
        </template>
      </table-list>
    </b-card>

    <modal-import-games
      v-model="importGames"
      title="Import Games"
      :end-point="`seasons/${this.$store.state.event.seasons.season.id}/games/import`"
      @cancel="importGames = false"
      @import-successful="handleImportSuccessful"
    />

  </section>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormRadioGroup, BButton, BFormSelect,
} from 'bootstrap-vue'
import AddGame from '@/views/events/manage/schedule/EventManageScheduleAddGame.vue'
import EditGame from '@/views/events/manage/schedule/EventManageScheduleEditGame.vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import SelectDivision from '@/components/forms/selects/SelectDivision.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import flatPickr from 'vue-flatpickr-component'
import TableList from '@/components/TableList.vue'
import ModalImportGames from '@/components/modals/ModalImport.vue'

import { mapState } from 'vuex'
import { formatGameDateAndTime } from '@core/utils/filter'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventSchedule',
  components: {
    BCard,
    BFormGroup,
    BButton,
    BFormSelect,
    BCol,
    BFormRadioGroup,
    AddGame,
    EditGame,
    CardHeaderFilters,
    SelectTeam,
    SelectDivision,
    TableList,
    flatPickr,
    ModalImportGames,
  },
  data() {
    return {
      isLoading: true,
      addGame: false,
      editGame: false,
      teams: ['home', 'visitor'],
      search: null,
      metaData: {
        currentPage: 1,
        perPage: localStorage.getItem('perPage') || 10,
      },
      games: [],
      game: {},
      importGames: false,
      dateRanges: [
        { text: 'Entire Season', value: { selected: 'Entire Season', start_date: null, end_date: null } },
        { text: 'Upcoming', value: { selected: 'Upcoming', start_date: new Date().toLocaleDateString('fr-CA'), end_date: null } },
      ],
      customDateRange: null,
      fields: [
        'date',
        'venue',
        {
          label: 'home',
          key: 'teams.home.name',
        },
        {
          label: 'Score',
          key: 'teams.home.score',
        },
        {
          label: 'visitor',
          key: 'teams.visitor.name',
        },
        {
          label: 'Score',
          key: 'teams.visitor.score',
        },
      ],
      filters: {
        division: 'All',
        team: 'All',
        venue: 'All',
        dateRange: {
          selected: 'Entire Season',
          start_date: null,
          end_date: null,
        },
      },
    }
  },
  computed: {
    ...mapState('event/seasons', ['seasons', 'season']),
    ...mapState('event/venues', ['venues']),
    gamesFormatted() {
      // Display dates and times in correct format.
      return this.games.map(game => {
        const { date, time } = formatGameDateAndTime(game.date, game.time)

        return {
          ...game,
          dateFormatted: date,
          timeFormatted: time,
        }
      })
    },
  },
  watch: {
    season: {
      immediate: true,
      handler() {
        if (!this.season) {
          return
        }

        this.getGames()
      },
    },
  },
  async created() {
    // Get venues.
    try {
      await this.$store.dispatch('event/venues/getVenues', { query: `event_id=${this.$route.params.event}&playing_surfaces=true` })
    } catch (error) {
      flashError(error)
    }
  },
  methods: {
    /**
     * This is needed so the home and visitor cells can be rendered in a loop.
     * @param key
     * @returns {string}
     */
    dynamicCell(key) {
      return `cell(${key})`
    },
    // Dates
    combineDateAndTime(date, time) {
      return new Date(`${date}T${time}`)
    },
    handleDateRangeChange() {
      // this.metaData.total = 1
      this.updatePage(1)
      this.customDateRange = null
    },
    handleCustomDateRangeChange(dates) {
      // Wait until end date is selected.
      if (dates.length < 2) {
        return
      }

      const [startDate, endDate] = this.customDateRange.split(' to ')

      this.filters.dateRange = {
        selected: 'Custom',
        start_date: startDate,
        end_date: endDate || startDate,
      }

      this.updatePage(1)
    },
    sortGamesByDate() {
      // Turn strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
      this.games.sort((a, b) => this.combineDateAndTime(a.date, a.time) - this.combineDateAndTime(b.date, b.time))
    },
    // Modals
    handleImportSuccessful() {
      this.importGames = false
      this.getGames()
    },
    showEditGame(game) {
      this.game = this.games.find(g => g.id === game.id)
      this.editGame = true
    },
    handleEditGameCancel() {
      this.editGame = false
      this.game = {}
    },
    updateGame(game) {
      this.games.splice(
        this.games.findIndex(g => g.id === game.id),
        1,
        game,
      )

      this.sortGamesByDate()
      this.editGame = false
    },
    handleDeleteGame(game) {
      this.games.splice(this.games.findIndex(g => g.id === game.id), 1)
      this.metaData.total--
    },
    addGameToSchedule(game) {
      // Don't add if teams do not match team filter.
      if (this.filters.team !== 'All' && !(game.teams.home?.team_id === this.filters.team || game.teams.visitor?.team_id === this.filters.team)) {
        return
      }

      // Don't add if teams' divisions do not match division filter.
      if (this.filters.division !== 'All' && !(game.teams.home?.division.id === this.filters.division && game.teams.visitor?.division.id === this.filters.division)) {
        return
      }

      // Don't add if venue does not match venues filter.
      if (this.filters.venue !== 'All' && !(game.teams.venue?.id === this.filters.venue)) {
        return
      }

      this.games.push(game)
      this.sortGamesByDate()
      this.metaData.total += 1
    },
    // Pagination / Ajax
    handleResetFilter(filters) {
      this.filters = filters
      this.customDateRange = null
      this.getGames()
    },
    updatePage(pageNumber) {
      this.metaData.currentPage = pageNumber
      this.getGames()
    },
    buildQuery() {
      const query = new URLSearchParams({
        season_id: this.season.id,
        per_page: this.metaData.perPage,
        page: this.metaData.currentPage,
      })

      if (this.filters.division !== 'All') {
        query.append('division_id', this.filters.division)
      }

      if (this.filters.team !== 'All') {
        query.append('team_id', this.filters.team)
      }

      if (this.filters.venue !== 'All') {
        query.append('venue_id', this.filters.venue)
      }

      if (this.filters.dateRange.start_date) {
        query.append('start_date', this.filters.dateRange.start_date)
      }

      if (this.filters.dateRange.end_date) {
        query.append('end_date', this.filters.dateRange.end_date)
      }

      return query
    },
    async getGames() {
      this.isLoading = true

      try {
        const { data } = await this.$http.get(`/games?${this.buildQuery().toString()}`)

        this.games = data.data
        this.metaData = {
          currentPage: data.meta.current_page,
          perPage: data.meta.per_page,
          total: data.meta.total,
        }
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.isLoading = false
      this.$Progress.finish()
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  [dir] .schedule-table td {
    padding: 0.72rem 1rem
  }
}
</style>

<style lang="scss" scoped>
td .sub-title {
  font-size: .9rem;
}

.btn-group ::v-deep .btn {
  font-size: .9rem
}

#date-range {
  ::v-deep .btn {
    display: flex;
    align-items: center;
    @media(max-width: 576px) {
      font-size: 11px;
    }
  }
}
</style>
