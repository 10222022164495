<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    @validated="updateGame"
  >
    <Modal
      ref="modal"
      scrollable
      title="Edit Game"
      size="lg"
      modal-class="modal-edit-game"
      :value="value"
      :submit-buttons="{
        loading: form.isLoading,
        invalid: observer.failed
      }"
      v-bind="$attrs"
      v-on="$listeners"
      @save="$refs.validationForm.validate()"
    >
      <!--    <template #modal-header>-->

      <!--      <h5 class="modal-title">-->
      <!--        Edit Game-->
      <!--      </h5>-->

      <!--      <div class="text-right mr-2">-->
      <!--        <b-button-->
      <!--          variant="outline-light"-->
      <!--          size="sm"-->
      <!--          class="mr-1"-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            icon="ArrowLeftIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Prev</span>-->
      <!--        </b-button>-->
      <!--        <b-button-->
      <!--          variant="outline-light"-->
      <!--          size="sm"-->
      <!--        >-->
      <!--          <span>Next</span>-->
      <!--          <feather-icon-->
      <!--            icon="ArrowRightIcon"-->
      <!--            class="ml-50"-->
      <!--          />-->
      <!--        </b-button>-->
      <!--      </div>-->

      <!--    </template>-->
      <!-- Seek Between Games -->
      <template #prepend-buttons>
        <div class="d-inline seek-btn-container">
          <!-- Previous Game -->
          <b-button
            variant="outline-primary"
            class="mr-1 previous-btn"
            size="sm"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            <span>Prev</span>
          </b-button>

          <!-- Next Game -->
          <b-button
            variant="outline-primary"
            class="mr-1 next-btn"
            size="sm"
          >
            <span>Next</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
        </div>
      </template>

      <!-- Details -->
      <b-card-title class="mt-2">
        Details
      </b-card-title>

      <form-game
        :game="form"
        :venues="venues"
      />

      <!-- Video -->
      <section class="mt-2">
        <b-card-title>Video</b-card-title>

        <b-form-row>
          <b-col sm="6">
            <form-validation-field
              v-model="form.embed_url"
              name="embed_url"
              label="Stream Url"
            />
          </b-col>
          <b-col sm="6">
            <form-validation-field
              v-model="form.video_url"
              name="video_url"
              label="Video Url"
            />
          </b-col>
        </b-form-row>
      </section>
    </Modal>
  </validation-form>
</template>

<script>
import {
  BCardTitle, BFormRow, BCol, BButton,
} from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormGame from '@/components/forms/FormGame.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    BCardTitle,
    BFormRow,
    BCol,
    BButton,
    Modal,
    ValidationForm,
    FormGame,
    FormValidationField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    game: {
      required: true,
      type: Object,
    },
    venues: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: new Form({
        date: '',
        time: '',
        venue_id: null,
        playing_surface_id: null,
        home_team_id: null,
        home_score: null,
        visitor_team_id: null,
        visitor_score: null,
        type: null,
        note: null,
        video_url: null,
        embed_url: null,
        status: null,
      }),
    }
  },
  computed: mapState('event/teams', ['teams']),
  watch: {
    game(game) {
      if (Object.keys(game).length === 0) {
        return
      }

      Object.assign(this.form, {
        ...game,
        playing_surface_id: game.playing_surface?.id,
        venue_id: game.venue?.id,
        home_team_id: game.teams.home.team_id,
        visitor_team_id: game.teams.visitor.team_id,
        home_score: game.teams.home.score,
        visitor_score: game.teams.visitor.score,
      })
    },
  },
  methods: {
    updateGame() {
      this.form.put(`/games/${this.game.id}`)
        .then(() => {
          const venue = this.venues.find(v => v.id === this.form.venue_id)
          const home = this.teams.find(team => team.id === this.form.home_team_id)
          const visitor = this.teams.find(team => team.id === this.form.visitor_team_id)
          const playingSurface = venue
            ? venue.playing_surfaces.find(surface => surface.id === this.form.playing_surface_id)
            : null

          this.$emit('update-game', {
            ...this.game,
            ...this.form.data(),
            teams: {
              home: {
                ...home,
                team_id: home.id,
                score: this.form.home_score,
              },
              visitor: {
                ...visitor,
                team_id: visitor.id,
                score: this.form.visitor_score,
              },
            },
            venue,
            playing_surface: playingSurface,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@media (min-width: 576px) {
  [dir=ltr] .modal-edit-game .modal-lg {
    width: 1000px;
    max-width: 90%;
  }
}

@media (max-width: 505px) {
  [dir=ltr] .modal-edit-game .modal-footer {
    justify-content: center;
    flex-direction: row-reverse;
  }

  .seek-btn-container {
    display: block !important;
    margin-bottom: 2rem;
  }
}

</style>
